var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("div", { staticStyle: { "margin-top": "36px" } }, [
        _c("div", { staticClass: "loader" })
      ])
    : _c("div", [
        _vm.successfulResponse
          ? _c("div", { staticClass: "margin" }, [
              _vm._v(
                "\n    " +
                  _vm._s(
                    "You (" +
                      decodeURIComponent(_vm.recipient) +
                      ") have been blocked from having " +
                      _vm.frequency +
                      " screenshot emails sent from the following account: " +
                      decodeURIComponent(_vm.accountId)
                  ) +
                  "\n  "
              )
            ])
          : _c("div", [
              _c("span", [
                _vm._v(
                  "Issue blocking your email on that account, please try again later."
                )
              ])
            ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }